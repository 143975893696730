<template>
  <div id="vm-cola-homev2" class="vm-cola-homev2">
    <HomeBanners type="primary" />
    <div class="vm-cola-homev2__label">
      <p>Hi Bestie! Yuk, pilih salah satu untuk dapatkan beauties incaranmu!</p>
    </div>

    <div class="vm-cola-homev2__actions">
      <div class="buy-product" @click="goToCatalog">
        <p class="buy-product__title">Shop Product</p>
        <img src="/static/img/icons/icon-buy-products-2.png" alt="ic-buy-product" />
        <p class="action">Eksplor beauties favorit <br />& belanja super easy!</p>
      </div>
      <div
        v-if="beautyATMData && beautyATMData.is_active && beautyATMData.is_active_in_vm_cola"
        class="play-games"
        @click="goToGames"
      >
        <p class="play-games__title">Play Games</p>
        <img src="/static/img/icons/icon-play-games-1.svg" alt="ic-play-games" />
        <p class="action">
          Menangkan SOCO Points,<br />
          Vouchers, hingga FREE <br />
          beauties!
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ls from '@/components/helpers/local-storage';
import helpers from '@/mixins/helpers';
import autotimeout from '@/mixins/auto-timeout';

export default {
  name: 'vmColaHomeV2',
  components: {
    HomeBanners: () => import('@/components/vm-cola/snippets/home-banners/home-banners'),
  },
  mixins: [helpers, autotimeout],

  computed: {
    beautyATMData() {
      return this.$store.state.beautyAtmData || null;
    },
  },

  created() {
    this.resetCart();
    // Promise.all([this.resetCart(), this.fetchBeautyData()]);
  },
  methods: {
    resetCart() {
      ls.remove('cart');
      this.$store.dispatch('resetCart', 0);
    },
    goToCatalog() {
      this.$router.push('/vmcola/products');
    },
    goToGames() {
      this.$router.push('/vending-machine/login');
    },
    fetchBeautyData() {
      this.$store.dispatch('getBeautyAtmData');
    },
  },
};
</script>
